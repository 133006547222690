import { Environment } from "../app/classes/flow/base/Environment";

export const ENVIRONMENT: Environment = {
  PRODUCTION: false,
  ORGANISATION: "Fixteam",
  API_URL: "https://dev.api.fixteam.emmengeeftenergie.nl/",
  CLIENT_ID: "068be95c-8e56-4cdb-8be0-5347038603f5",
  AUTHORITY: "https://bespaartenergiedev.b2clogin.com/bespaartenergiedev.onmicrosoft.com/B2C_1_signupandsignin",
  KNOWN_AUTHORITY: "https://bespaartenergiedev.b2clogin.com",

  SOCIALS: {
    SLOGAN: "Wij hebben het zo voor u gefixt",
    LINKS: {
      MAIN: "https://www.emmengeeftenergie.nl/",
      PRIVACY: "https://www.emmengeeftenergie.nl/Beheer/Privacyverklaring/default.aspx",
      DISCLAIMER: "",
    },
  },

  EXTRA_TRANSLATIONS_SUFFIX: [".fixteam"],
  MODULES: [
    "AREA_ACTIONS",
    "SHOW_ADDRESSDETAILS_TO_COACH_ROLE",
    "COACH_FINISH_REQUEST",
    "SHOW_ANOTHER_COACHES_ECS",
    "NOTE",
    "EDIT_ENERGY_CONSULT",
    "SHOW_CONTACT_INFO_ON_NEW_REQUESTS",
    "PHONE_NUMBER_REQUIRED",
    "HIDE_AREA_ACTIONS_NEW_REQUEST_PAGE",
    "NEW_REQUEST_COACH",
    "NEW_REQUEST_COORDINATOR",
    "CKD_SUBF",
    "ALLOW_HISTORIC_APPOINTMENTS",
    "CUSTOM_SPLIT_HEADER_LOGO",
    "MAIL_TIMELINE",
    "REPORT_CATEGORIES",
    "STATISTICS_DASHBOARD",
  ],
  AREA_OF_OPERATIONS: {
    MATCHES: [{ type: "include", key: "gemeentenaam", value: ["EMMEN"] }],
  },
  ALLOWED_SPECIALTIES: [11],
  CUSTOM_HEADER_LOGOS: [
    {
      urls: [
        {
          name: "COACH_EMMEN",
          url: "https://dev.coach.epf.bespaartenergie.net/",
          urlType: "external",
          roles: ["coordinator"],
          default: false,
        },
        {
          name: "",
          url: "/",
          urlType: "internal",
          roles: ["guest", "resident"],
          default: false,
        },
        {
          name: "",
          url: "/content",
          urlType: "internal",
          roles: [],
          default: true,
        },
      ],
      imageRoute: "assets/organisation/images/custom_header_logo/emmengeeftenergie-logo.png",
    },
    {
      urls: [
        {
          name: "FIXTEAM_EMMEN",
          url: "/",
          urlType: "internal",
          roles: ["coordinator"],
          default: false,
        },
        {
          name: "",
          url: "/",
          urlType: "internal",
          roles: ["guest", "resident"],
          default: false,
        },
        {
          name: "",
          url: "/content",
          urlType: "internal",
          roles: [],
          default: true,
        },
      ],
      imageRoute: "assets/organisation/images/custom_header_logo/fixteam-logo.png",
    },
  ],
};
